import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import logo from '../images/treuefuchs.svg';
import { Tab, Tabs } from '@mui/material';
import isIOS from '../util/is-ios';

export default function ClosedBeta() {
    const [tabIndex, setTabIndex] = useState<number>(0);
    useEffect(() => {
        if (isIOS()) {
            setTabIndex(1);
        }
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return <Container maxWidth="md">
        <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Android" />
            <Tab label="iOS" />
        </Tabs>
        {tabIndex === 0 && <div>
            <h2>Anleitung Android</h2>
            <p>Kommt in Kürze</p>
        </div>}
        {tabIndex === 1 && <div>
            <h2>Anleitung iOS</h2>
            <p>Kommt in Kürze</p>
        </div>}
    </Container>;
}